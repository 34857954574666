@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.content-heading{
    letter-spacing: 0.2em;
}

/* Navbar */

.navbar-logo{
    flex-basis: 10%;
}

.navbar-opt{
    flex-basis: 90%;
}

@media (max-width: 768px) {
    .navbar-logo{
        margin-left: 2.5rem;
    }
}

@keyframes zoom-in {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

/*home*/

.home-txt{
    text-align: left;
    color: white;
}

.home-img{
    filter: brightness(40%);
}

.transition-slide {
    transition: transform 0.5s ease-out; /* Adjust the duration and timing function as needed */
  }
  

@media (max-width: 768px) { 
    .home-txt{
        letter-spacing: 0.2em;
        line-height: 1.5;
    }
}

/* about */
.count-value-container {
    color: red;
    font-weight: bold;
    font-size: 23px;
}

@media (max-width: 768px) { 
    .home-txt{
        padding-top: 12rem;
        padding-left: 4rem;
        font-size: 36px;
    }
}

@keyframes slide {
    from{
        transform: translateX(10%);
    }
    to{
        transform: translateX(-90%);
    }
}

.logos{
    overflow: hidden;
    white-space: nowrap;
}

.logos-slide{
    animation: 30s slide infinite linear;

}

.logos:hover .logos-slide{
    animation-play-state: paused;
}

/* .client-logo{
    aspect-ratio: 3/2;
    object-fit: contain;
} */

/* services */

.service-card{
    border-color: #222222;
    background-color: #222222;
    transition: background-color 0.3s ease;
}

/* .service-card:hover{
    background-color: rgba(239, 68, 68);
}239, 68, 68


.service-card:hover .srvc-icon{
    color: #222222;
} */

.serv-img{
    filter: brightness(60%);
    /* height: 60%; */
} 

.choose-card{
    border-color: #222222;
    background-color: #222222;
    transition: background-color 0.3s ease;
    transition: h1 0.3s ease;
    transition: p 0.3s ease;
}

.choose-card:hover{
    background-color: black;
}

.choose-card:hover h1 {
    color: red;
  }

.choose-card:hover p{
    color: white;
}


/* PROJECT */
